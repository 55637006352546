import React, { useCallback } from 'react';
import javascriptBasico from '../assets/coursesSection/javascriptBasico.webp';
import javascriptAvanzado from '../assets/coursesSection/javascriptAvanzado.webp';
import mentoriasPrivadas from '../assets/coursesSection/mentoriasPrivadas.webp';
import { Link } from 'react-router-dom'; 
import TagManager from 'react-gtm-module';

const resources = [
  {
    title: 'Javascript Basico',
    description: 'Explorarás los metodos nativas y aprenderás a desarrollar aplicaciones web modernas. Al graduarte, serás capaz de construir soluciones web interactivas y aplicar estos conocimientos a cualquier framework JavaScript.',
    imgSrc: javascriptBasico,
    bgColor: 'bg-yellow-500',
    textColor: 'text-yellow-500',
    price: '119.999'
  },
  {
    title: 'Javascript Avanzado',
    description: 'Aprenderás a aplicar las mejores prácticas, incluyendo la refactorización de código, la creación de sitios web seguros con HTTPS y el uso efectivo de Promesas con bloques Try-Catch.',
    imgSrc: javascriptAvanzado,
    bgColor: 'bg-purple-500',
    textColor: 'text-purple-500',
    price: '100.000'
  },
  {
    title: 'Mentorias Privadas',
    description: '¡Eleva tu Potencial con Sesiones Privadas de Mentoría de 1 Hora vía Zoom para resolver cualquier duda!',
    imgSrc: mentoriasPrivadas,
    bgColor: 'bg-cyan-500',
    textColor: 'text-cyan-500',
    price: '13999'
  },
  // Agrega más recursos aquí según sea necesario
];

const FeaturedResources = () => {

  const handleOpenGoogleDrive = useCallback((title) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'programButtonClick',
        title: title 
      }
    });

    openGoogleDrive(title);
  }, []);

  const openGoogleDrive = (title) => {
    let url;
    switch (title) {
      case 'Javascript Basico':
        url = 'https://drive.google.com/file/d/1343Gq1ISUEa89JDwVDKT8pn2n4wueSqp/view?usp=sharing';
        break;
      case 'Javascript Avanzado':
        url = 'https://drive.google.com/file/d/1WYuAdZAT1dwafqGNj9Qfr_u19b9yi1uP/view?usp=sharing';
        break;
      case 'Mentorias Privadas':
        url = 'https://drive.google.com/file/d/1CNVEFbIpHL6DvESQYu9ul1j66OjbwMT5/view?usp=drive_link';
        break;
      case 'Excel Basico':
        url = 'https://drive.google.com/file/d/1dAcut_UkQ5KYB8dvuhOC3u6Tvgg4IjXA/view?usp=sharing';
        break;
      default:
        console.error('Título no reconocido');
        return;
    }

    window.open(url, '_blank');
  };

  const eventClickDataLayer = (title, price) => {
    // Envía un evento a Google Tag Manager cuando se hace clic en el enlace
    TagManager.dataLayer({
      dataLayer: {
        event: 'comprarCursoCarrousel',
        title: title,
        price: price
      }
    });
  };

  return (
    <div className="bg-gradient-to-r from-slate-500 to-slate-800" id="Cursos">
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-6xl font-semibold text-center text-lime-300 dark:text-gray-200 mb-8 mt-20 font-bold">
          Nuestros Cursos
        </h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-20">
          
          {resources.map(({title, textColor, description, bgColor, imgSrc, price}, index) => (
            <div key={index} className="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden">
              <img src={imgSrc} alt={description} title={title} className="w-full h-64 object-cover" />
              <div className="p-4 md:p-6">
                <h3 className={`text-xl font-semibold ${textColor} dark:${textColor} mb-2`}>
                  {title}
                </h3>
                <p className="text-gray-700 dark:text-gray-300 mb-4 two-lines">
                  {description}
                </p>
                <Link
                  target='_blank'
                  href="#"
                  to={`/curso/${title}/`}
                  state={{ title: title }}
                  onClick={() => eventClickDataLayer(title, price)}
                  className={`inline-block ${bgColor} hover:${bgColor}-600 text-white px-4 py-2 rounded-full`}
                >
                  Ver más
                </Link>
                <button
                onClick={() => handleOpenGoogleDrive(title)}
                  className={`inline-block ${bgColor} hover:${bgColor}-600 text-white px-4 py-2 rounded-full ml-10`}>
                  Mira nuestro programa
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedResources;
