import React from 'react';

const navigation = [
  { name: 'Proyectos', href: '#Proyectos' },
  { name: 'Testimonios', href: '#Testimonios' },
  { name: 'Cursos', href: '#Cursos' },
  { name: 'Blog', href: '#Blog' },
  { name: 'Preguntas Frecuentes', href: '#PreguntaFrecuentes' }
];

const Navbar = () => {
  return (
    <header className="fixed inset-x-0 top-0 z-30 mx-auto w-full max-w-screen-md border border-lime-100 py-3 shadow backdrop-blur-lg md:top-6 md:rounded-3xl lg:max-w-screen-lg">
      <div className="px-4">
        <div className="flex items-center justify-between">
          {/* Logo Section */}
         

          {/* Navigation Links Section */}
          <nav className="hidden md:flex flex-1 justify-center space-x-4 md:space-x-6">
            {navigation.map((item) => (
              <a
                key={item.name}
                className="inline-block rounded-lg px-2 py-1 text-xl font-medium text-gray-900 transition-all duration-200 hover:bg-lime-200 hover:text-gray-900"
                href={item.href}
              >
                {item.name}
              </a>
            ))}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
