import React from 'react';

export default function TimelineWithIcon() {
  return (
    <section>
      <div className="bg-gradient-to-r from-slate-500 to-slate-800 text-white py-6">
        <div className="container mx-auto flex flex-col items-start md:flex-row my-8 md:my-16">
          <div className="flex flex-col w-full sticky md:top-20 lg:w-1/3 mt-2 md:mt-8 px-4">
            <p className="ml-2 text-yellow-300 uppercase tracking-tight text-xs">Mi Progreso</p>
            <p className="text-2xl md:text-3xl font-semibold leading-snug mb-2">Trabajando día a día</p>
            <p className="text-xs md:text-sm text-gray-50 mb-3">
              Esta es una guía de mi vida en la programación. Lo que comenzó como una idea ha tenido un gran impacto en mi vida.
            </p>
          </div>

          <div className="ml-0 md:ml-8 lg:w-2/3">
            <div className="container mx-auto w-full h-full">
              <div className="relative wrap overflow-hidden p-6">
                <div className="absolute h-full border" style={{ right: '50%', border: '1px solid #FFC100', borderRadius: '1%' }}></div>
                <div className="absolute h-full border" style={{ left: '50%', border: '1px solid #FFC100', borderRadius: '1%' }}></div>
                
                {/* Timeline items */}
                <TimelineItem
                  date="13 Diciembre, 2023"
                  title="Lanzamiento de la plataforma"
                  description="Lancé mi plataforma para compartir conocimiento sobre programación, desarrollo web y proyectos interactivos."
                  isLeft={true}
                />
                <TimelineItem
                  date="23 Mayo, 2024"
                  title="Redes Sociales"
                  description="Empecé a crear contenido en redes para compartir conocimientos y actualizaciones."
                  isLeft={false}
                />
                <TimelineItem
                  date="13 Jul, 2024"
                  title="Reconocimiento de Google"
                  description="Recibí un reconocimiento de Google por cumplir objetivos de insignias en la comunidad."
                  isLeft={true}
                />
                <TimelineItem
                  date="19 Sep, 2024"
                  title="Freelancer"
                  description="Comencé a trabajar como freelancer para ayudar a otros a crear sus plataformas y aplicaciones."
                  isLeft={false}
                />
                 <TimelineItem
                  date="15 Oct, 2024"
                  title="Elemental Studio"
                  description="Junto a mi compañero de trabajo, lanzamos Elemental Studio, una empresa de soluciones tecnológicas."
                  isLeft={true}
                  showButton={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function TimelineItem({ date, title, description, isLeft, showButton }) {
  return (
    <div className={`mb-6 flex justify-between items-center w-full ${isLeft ? 'flex-row-reverse left-timeline' : 'right-timeline'}`}>
      <div className="order-1 w-4/12"></div>
      <div className={`order-1 w-6/12 px-2 py-3 ${isLeft ? 'text-right' : 'text-left'}`}>
        <p className="mb-2 text-xs text-yellow-300">{date}</p>
        <h4 className="mb-2 font-bold text-md md:text-lg">{title}</h4>
        <p className="text-xs md:text-sm leading-snug text-gray-50">
          {description}
        </p>
        {showButton && (
          <a className="mt-2 bg-pink-300 text-black px-4 py-2 rounded-md inline-block" href="https://elementalstudio.dev/" target="_blank" rel="noopener noreferrer">Elemental Studio</a>
        )}
      </div>
    </div>
  );
}
