import { Link } from "react-router-dom";
import imgCristian from '../assets/1578750284142.webp';
import { useState } from 'react';

// Definir un objeto de rutas dinámicas basado en el ID de los posts
const routes = {
  2: "/faq-entreview-junior/",
  3: "/faq-javascript-semis-senior/",
  4: "/web-design-tips/",
  5: "/article-component/",
  6: "/tendencias-interactividad-diseño-web/",
};

// Función para obtener la ruta correcta para cada post
const getPostLink = (post) => {
  return routes[post.id] || `/blog/${post.category.title}/`;
};

const posts = [
  {
    id: 1,
    title: 'La IA no saca trabajo?',
    description:
      'La respuesta los sorprenderá',
    date: 'Feb 19, 2024',
    datetime: '19-02-2024',
    category: { title: 'Programacion' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 2,
    title: 'Preguntas entrevista Junior',
    description:
      'Programadores Javascript',
    date: 'Ago 03, 2024',
    datetime: '03-08-2024',
    category: { title: 'Pregunta Entrevista' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 3,
    title: 'Preguntas entrevista Semis Senior',
    description:
      'Programadores Javascript',
    date: 'Oct 02, 2024',
    datetime: '02-10-2024',
    category: { title: 'Pregunta Entrevista' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 4,
    title: 'Tips de codigo',
    description:
      'Consejos a la hora de diseñar paginas web',
    date: 'Oct 10, 2024',
    datetime: '10-10-2024',
    category: { title: 'Consejos' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 5,
    title: 'Frontend con UX/UI',
    description:
      '6 Ventajas de tener conocimientos en UI/UX',
    date: 'Oct 27, 2024',
    datetime: '27-10-2024',
    category: { title: 'Lectura entretenida' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  },
  {
    id: 6,
    title: 'Tendencias de Interactividad en Diseño Web ',
    description:
      'Los estilos mas usados en 2024',
    date: 'Nov 14, 2024',
    datetime: '14-11-2024',
    category: { title: 'Lectura entretenida' },
    author: {
      name: 'Cristian Cabrera',
      role: 'CEO',
      imageUrl: imgCristian,
    },
  }
];

export default function Blog() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [postsPerPage, setPostsPerPage] = useState(getPostsPerPage());

  // Función para determinar cuántos posts mostrar según el ancho de la pantalla
  function getPostsPerPage() {
    if (typeof window !== 'undefined') {
      if (window.innerWidth < 640) return 1; // móvil
      if (window.innerWidth < 1024) return 2; // tablet
      return 3; // desktop
    }
    return 3; // valor por defecto
  }

  // Actualizar postsPerPage cuando cambie el tamaño de la ventana
  useState(() => {
    function handleResize() {
      setPostsPerPage(getPostsPerPage());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex + postsPerPage >= posts.length ? 0 : prevIndex + postsPerPage
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex - postsPerPage < 0 ? posts.length - postsPerPage : prevIndex - postsPerPage
    );
  };

  return (
    <div className="bg-gradient-to-r from-slate-500 to-slate-800 py-12 sm:py-16 md:py-24" id="Blog">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold tracking-tight text-black">
            Mira nuestro Blog
          </h2>
          <p className="mt-2 text-base sm:text-lg font-bold leading-8 text-lime-400">
            Fíjate lo que opinan nuestros expertos sobre temáticas cotidianas
          </p>
        </div>

        <div className="relative mt-6 sm:mt-8 lg:mt-10">
          {/* Botón Anterior */}
          <button
            onClick={prevSlide}
            className="absolute -left-2 sm:-left-4 top-1/2 -translate-y-1/2 z-10 bg-black/30 hover:bg-black/50 text-white p-1 sm:p-2 rounded-full w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center"
            aria-label="Anterior"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="w-4 h-4 sm:w-6 sm:h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </button>

          {/* Contenedor de posts con ancho fijo */}
          <div className="mx-auto max-w-7xl overflow-hidden">
            <div className="flex transition-transform duration-300 ease-in-out" 
                 style={{ transform: `translateX(-${currentIndex * (100 / postsPerPage)}%)` }}>
              {posts.map((post) => (
                <article 
                  key={post.id}
                  className={`w-full ${
                    postsPerPage === 1 ? 'sm:w-full' : 
                    postsPerPage === 2 ? 'sm:w-1/2' : 
                    'sm:w-1/2 lg:w-1/3'
                  } flex-shrink-0 px-2 sm:px-4`}
                >
                  <div className="bg-white dark:bg-gray-800 shadow-lg rounded-lg p-3 sm:p-5 h-full transition-transform duration-300 ease-in-out hover:shadow-xl hover:-translate-y-2">
                    <div className="flex items-center gap-x-2 sm:gap-x-4 text-xs text-gray-500">
                      <time dateTime={post.datetime} className="text-gray-500">
                        {post.date}
                      </time>
                      <a className="relative z-10 rounded-full bg-slate-200 text-slate-600 px-2 sm:px-3 py-1 sm:py-1.5 font-medium hover:bg-slate-300 dark:bg-gray-600 dark:text-gray-200 hover:dark:bg-gray-700">
                        {post.category.title}
                      </a>
                    </div>
                    <div className="group relative mt-3 sm:mt-4">
                      <h3 className="text-base sm:text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-700 dark:text-gray-100 dark:group-hover:text-gray-300">
                        <Link 
                          target='_blank'
                          to={getPostLink(post)}  
                        >
                          <span className="absolute inset-0" />
                          {post.title}
                        </Link>
                      </h3>
                      <p className="mt-2 sm:mt-3 line-clamp-3 text-xs sm:text-sm leading-5 sm:leading-6 text-gray-600 dark:text-gray-400">
                        {post.description}
                      </p>
                    </div>
                    <div className="relative mt-4 sm:mt-8 flex items-center gap-x-2 sm:gap-x-4">
                      <img 
                        src={post.author.imageUrl} 
                        alt="" 
                        className="h-8 w-8 sm:h-10 sm:w-10 rounded-full bg-gray-100 dark:bg-gray-700" 
                      />
                      <div className="text-xs sm:text-sm leading-5 sm:leading-6">
                        <p className="font-semibold text-gray-900 dark:text-gray-100">
                          <a target='_blank'>
                            <span className="absolute inset-0" />
                            {post.author.name}
                          </a>
                        </p>
                        <p className="text-gray-600 dark:text-gray-400">{post.author.role}</p>
                      </div>
                    </div>
                  </div>
                </article>
              ))}
            </div>
          </div>

          {/* Botón Siguiente */}
          <button
            onClick={nextSlide}
            className="absolute -right-2 sm:-right-4 top-1/2 -translate-y-1/2 z-10 bg-black/30 hover:bg-black/50 text-white p-1 sm:p-2 rounded-full w-8 h-8 sm:w-10 sm:h-10 flex items-center justify-center"
            aria-label="Siguiente"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2.5}
              stroke="currentColor"
              className="w-4 h-4 sm:w-6 sm:h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </button>

          {/* Indicadores de página */}
          <div className="flex justify-center mt-8 gap-2">
            {Array.from({ length: Math.ceil(posts.length / postsPerPage) }).map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentIndex(index * postsPerPage)}
                className={`h-2 w-2 rounded-full transition-all ${
                  index === Math.floor(currentIndex / postsPerPage)
                    ? 'bg-white w-4'
                    : 'bg-white/50'
                }`}
                aria-label={`Ir a página ${index + 1}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}